import React from 'react';

const Home = () => {

  return (
    <div className="home">
      <h1>Welcome to My Homepage</h1>
      <p>This is a basic React.js home page using an arrow function.</p>
    </div>
  );
}

export default Home;
